<template lang='pug'>

  div(class="confirm-card")
    q-card(class="no-shadow")
      q-card-section(class="confirm-card-title")
        span {{message}}

      q-card-actions(class="button-group row justify-center")
        q-btn(flat no-caps
              class="modal-form-cancel"
              :label="confirm_modal_locales.cancel"
              @click="confirm(false)")
        q-btn(flat no-caps
              class="modal-form-submit"
              :label="confirm_modal_locales.submit"
              @click="confirm(true)")
</template>


<script>
  import gridButtonGroup from '../grid/gridButtonGroup'
  import gridFilters from '../grid/gridFilters'
  import Profile from '../../view/profile'


  export default {
    data: function () {
      return {
        message: this.parentData.message
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      confirm_modal_locales() {
        return this.locales.confirm_modal[this.current_locale]
      }
    },

    components: {
    },

    methods: {

      confirm(val) {
        this.$emit('confirm', val)
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>

  @import "../../../assets/styles/forms/confirm-modal";

</style>